import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "using-special-characters"
    }}>{`Using Special Characters`}</h1>
    <p>{`Special characters can be problematic for screen readers accessing the UI.`}</p>
    <h3 {...{
      "id": "dont-use"
    }}>{`Don't Use`}</h3>
    <p>{`Don't use special characters in fields, check boxes or other UI elements, including (but not limited
to) the following characters:`}</p>
    <ul>
      <li parentName="ul">{`Ampersands (&)`}</li>
      <li parentName="ul">{`Slashes (/)`}</li>
      <li parentName="ul">{`Quotation marks ("")`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`San Francisco and other cities`}</li>
        <li parentName="ul">{`Edit/Delete (in a button or CTA)`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`San Francisco & other cities`}</li>
        <li parentName="ul">{`Choose only 1 primary action for the button: Edit, or Delete`}</li>
      </ul>
    </Dont>
    <h3 {...{
      "id": "use-only-in-certain-circumstances"
    }}>{`Use Only in Certain Circumstances`}</h3>
    <p>{`You can use the tilde (~) for custom labels or brackets (`}{`[ ]`}{`) for variables (tokenized items) when
you’re writing validation messages.`}</p>
    <h3 {...{
      "id": "tilda-"
    }}>{`Tilda (~)`}</h3>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Select a remit-from ~sponsor~ that isn't already in use for at least 1 other ~sponsor~.`}</li>
      </ul>
    </Do>
    <h3 {...{
      "id": "brackets--"
    }}>{`Brackets (`}{`[ ]`}{`)`}</h3>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Use a table for the selected element in this function: `}{`[variable]`}{`.`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      